.about {
  background: #111826;
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;

  .content {
    display: flex;
    flex-direction: column;
    color: #f2f2f2;
    padding: 5%;
    font-size: 20px;

    span {
      margin-bottom: 10px;
    }
  }

  .info {
    display: flex;
    margin-top: auto;
    flex-wrap: wrap;

    .email {
      color: #f2f2f2;
      display: inline-flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-right: 10px;
      margin-right: auto;
      margin-left: 5%;
      margin-bottom: 2.5%;

      &__icon {
        margin-right: 10px;
      }
    }

    .address {
      color: #f2f2f2;
      display: inline-flex;
      margin-left: auto;
      flex-direction: column;
      justify-content: flex-end;
      margin-right: 5%;
      margin-bottom: 2.5%;
    }
  }
}
