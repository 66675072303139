.top-bar {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  background: #111826;
  border-bottom: 1px solid #796767;

  .title {
    color: #f2f2f2;
    padding: 20px;
    margin-left: 5%;
    font-weight: bold;
    font-size: 30px;
  }

  .navigation {
    margin-left: auto;
    margin-right: 5%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;

    .button {
      &__label {
        &--active {
          border-bottom: 1px solid #f2f2f2;
        }
      }
    }
  }
}
