.App {
  text-align: center;
  background-color: #111826;
  height: 100vh;
}

.App-header {
  background-color: #111826;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 20px;
  color: white;
}
