.app-info-preview {
  width: 600px;
  padding: 30px;
  display: inline-flex;
  flex-direction: column;

  &__image {
    width: 100%;
  }

  &__badge {
    width: 40%;
    margin-bottom: 10px;
  }

  &__coming-soon {
    color: #f2f2f2;
    font-size: 20px;
  }

  &__title {
    color: #f2f2f2;
    font-size: 25px;
    text-align: center;
    margin-bottom: 10px;
  }

  &__description {
    color: #f2f2f2;
  }
}
