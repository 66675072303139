.home {
  background: #111826;
  height: 100%;
  width: 100%;

  .content {
    display: flex;
    flex-wrap: wrap;
    //align-items: flex-start;
    justify-content: flex-start;
  }
}
